import {Element} from "react-scroll";

import classes from './Specialists.module.css'
import lines from "../assets/img/lines.svg";
import link from "../assets/img/link-2.svg";
import arm from "../assets/img/arm.svg";

const Specialists = () => {
    return (
        <div className={classes.specialists}>
            <Element name="special" />
            <div className={classes.container}>
                <div className={classes.titleBlock}>
                    <h1 className={classes.title}>База фахівців та сервісів </h1>
                    <img className={classes.lines} src={lines} alt="Lines"/>
                </div>
                <div className={classes.content}>
                    <p>
                        Зараз потреба у психологічній підтримці величезна. І щоб ви могли опанувати тривогу та дійсно
                        отримати ті поради, які вам допоможуть, ми зібрали велику базу з психотерапевтами та
                        психологами, а також ресурсами-помічниками.
                    </p>
                    <p> Заглядайте у базу та дбайте про себе та інших
                    </p>
                </div>
                <div className={classes.arm}>
                    <img src={arm} alt="Arm"/>
                </div>
                <div className={classes.link}>
                    <a target="_blank" href="https://bit.ly/3IByrHj">
                        База фахівців та сервісів
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Specialists;