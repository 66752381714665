import {useEffect, useState} from "react";
import {Element} from "react-scroll";

import classes from "./Advices.module.css"
import lines from "../assets/img/lines.svg";
import arrow from "../assets/img/arrow.svg";
import smallArrow from "../assets/img/smallarrow.svg";

const Advices = () => {
    const [advices, setAdvices] = useState();
    const [fullAdvices, setFullAdvices] = useState();
    const [openDropdown, setOpenDropdown] = useState()
    const [clicked, setClicked] = useState(false)
    const fetchData = async () => {
        try {
            fetch(
                `${process.env.REACT_APP_API_URL}/advice`)
                .then((res) => res.json())
                .then((json) => {
                    if (json) {
                        setFullAdvices(json)
                        setAdvices(json.slice(0, 5))
                    }
                })
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        fetchData().then()
    }, [])

    const handleDropdown = (id) => {
        if (id === openDropdown) {
            setOpenDropdown()
        } else {
            setOpenDropdown(id)
        }
    }

    const showMoreHandler = () => {
        setAdvices(fullAdvices)
        setClicked(true)
    }


    return (
        <div className={classes.advices}>
            <Element name="advices" />
            <div className={classes.container}>
                <div className={classes.titleBlock}>
                    <h1 className={classes.title}>Поради з підтримки</h1>
                    <img className={classes.lines} src={lines} alt="Lines"/>
                </div>
                <div className={classes.adviceList}>
                    {
                        advices ? advices.map(i => (
                            <div key={i.id} className={classes.adviceContainer}>
                                <div className={classes.advice} onClick={() => handleDropdown(i.id)}>
                                    <p>{i.title}</p> <img src={arrow} alt="Arrow"/>
                                </div>
                                {
                                    openDropdown === i.id && <div className={classes.adviceText}>
                                        <p>
                                            {i.text}
                                        </p>
                                    </div>
                                }

                            </div>
                        )) : <h3>Поки що немає порад!</h3>
                    }
                </div>
                {
                    !clicked && <div onClick={showMoreHandler} className={classes.showMore}><p>показати все</p><img src={smallArrow} alt="Small Arrow"/></div>
                }
            </div>

        </div>
    )
}

export default Advices;