import {slide as Menu} from "react-burger-menu";
import {useState} from "react";
import {scroller, Link} from 'react-scroll'

import close from '../assets/img/x.svg'
import menu from '../assets/img/burger.svg'

import classes from './BurgerMenu.module.css'

const MenuBtn = () => {
    const [menuOpen, setMenuOpen] = useState(false)

    const handleStateChange = () => {
        setMenuOpen(!menuOpen)
    }

    const onClose = () => {
        setMenuOpen(false)
    }

    const scrollTo = () => {
        setMenuOpen(false)
        scroller.scrollTo('scroll-to-element', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
    }

    return (
        <Menu
            isOpen={menuOpen}
            onOpen={handleStateChange}
            onClose={handleStateChange}
            // onStateChange={() => handleStateChange}
            customCrossIcon={<img src={close} alt='cross'/>}
            pageWrapId={"page-wrap"}
            outerContainerId={"main"}
            right customBurgerIcon={<img alt='menu' style={{width: '20px', height: '20px'}} src={menu}/>}
            burgerButtonClassName={classes.burgerBtn}
            burgerBarClassName={classes.burgerBar}
            crossButtonClassName={classes.crossBtn}
            overlayClassName={classes.overlayBurger}
            menuClassName={classes.menuBurger}
            itemListClassName={classes.itemList}
        >
            <main id="pageWrap" className={classes.pageWrap}>
                <ul className={classes.burgerMenu}>
                    <Link className={classes.linkTo} to="about" onClick={() => {
                        scrollTo();onClose()
                    }}>
                        <li>
                            <p>Про хаб</p></li>
                    </Link>
                    <Link className={classes.linkTo} to="advices" onClick={() => {
                        scrollTo();onClose()
                    }}>
                        <li>
                            <p>Поради з підтримки</p></li>
                    </Link>
                    <Link className={classes.linkTo} to="consult" onClick={() => {
                        scrollTo();onClose()
                    }}>
                        <li>
                            <p>Запис на консультацію</p></li>
                    </Link>
                    <Link className={classes.linkTo} to="special"
                          onClick={() => {
                              scrollTo();
                              onClose();
                          }}>
                        <li>
                            <p>База фахівців та сервісів</p></li>
                    </Link>
                    <a className={classes.linkTo}
                       href="https://docs.google.com/forms/d/e/1FAIpQLScGxhE_-KWM2Si2Ie-JT2zdcrlgQd_WCUGXd2DekJ8RgxLfJQ/viewform"
                          >
                        <li>
                            <p>Залишити відгук</p></li>
                    </a>
                </ul>
            </main>
        </Menu>
    )
}

export default MenuBtn