import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import { InlineWidget } from "react-calendly";

import classes from "./Сalendly.module.css";

const Calendly = () => {

    const { id } = useParams();
    const [link, setLink] = useState('');

    useEffect(() => {
        if (id.toLowerCase() === 'ukraine') {
            setLink('https://bit.ly/3wgkk75')
        } else if (id.toLowerCase() === 'abroad') {
            setLink('https://bit.ly/3MZoTso')
        } else {
            return
        }
    }, [])

    return (
        <div>
            <Link to="/" className={classes.calendly}>На головну</Link>
            <InlineWidget url={link} />
        </div>
    );
};

export default Calendly;