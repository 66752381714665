import {Link} from "react-router-dom";
import {Element} from "react-scroll";

import classes from './Consultation.module.css'
import lines from "../assets/img/lines.svg";
import vector from "../assets/img/vector.svg";
import calendar from "../assets/img/calendar.svg";
import group from "../assets/img/Group.svg";
import appointment from "../assets/img/appointment.svg";


const Consultation = () => {
    return (
        <div className={classes.consultation}>
            <Element name="consult"/>
            <div className={classes.container}>
                <div className={classes.titleBlock}>
                    <h1 className={classes.title}>Запис на консультацію</h1>
                    <img className={classes.lines} src={lines} alt="Lines"/>
                </div>
                <div className={classes.steps}>
                    <div className={classes.step}>
                        <img src={calendar} alt="Calendar"/>
                        <p>Переходьте у calendly</p>
                    </div>
                    <img className={classes.arrow} src={vector} alt="Arrow"/>
                    <div className={classes.step}>
                        <img src={group} alt="Group"/>
                        <p>Обирайте фахівця, з яким би хотіли працювати</p>
                    </div>
                    <img className={classes.arrow} src={vector} alt="Arrow"/>
                    <div className={classes.step}>
                        <img src={appointment} alt="Appointment"/>
                        <p>Бронюйте день та час</p>
                    </div>
                </div>
                <span className={classes.underline}/>
                <div className={classes.appointment}>
                    <p>Для запису на консультацію оберіть ваше місце знаходження</p>
                    <div className={classes.appointmentButtons}>
                        <Link to="/calendly/ukraine" className={classes.calendly}>Я в Україні</Link>
                        <Link to="/calendly/abroad" className={classes.calendly}>Я в іншій країні</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Consultation;