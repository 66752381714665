// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&family=Montserrat:wght@300;400;500;600;700&family=Pangolin&family=Roboto:wght@300;400;500;700;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "* {\n  font-family: 'Roboto', sans-serif;\n}\n\n#react-burger-menu-btn {\n  left: unset!important;\n  top: unset!important;\n  width: 46px!important;\n  height: 32px!important;\n}\n\n.bm-menu-wrap {\n  background: #fff;\n  top: 0;\n  border-left: 1px solid #DFE3EE;\n  width: 100% !important;\n}", "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AACA;EACE,iCAAiC;AACnC;;AAEA;EACE,qBAAqB;EACrB,oBAAoB;EACpB,qBAAqB;EACrB,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,MAAM;EACN,8BAA8B;EAC9B,sBAAsB;AACxB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&family=Montserrat:wght@300;400;500;600;700&family=Pangolin&family=Roboto:wght@300;400;500;700;900&display=swap');\n* {\n  font-family: 'Roboto', sans-serif;\n}\n\n#react-burger-menu-btn {\n  left: unset!important;\n  top: unset!important;\n  width: 46px!important;\n  height: 32px!important;\n}\n\n.bm-menu-wrap {\n  background: #fff;\n  top: 0;\n  border-left: 1px solid #DFE3EE;\n  width: 100% !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
