import { scroller , Link} from 'react-scroll'

import classes from './Header.module.css'

import logo from '../assets/img/logo.jpeg'
import MenuBtn from "./BurgerMenu";

const scrollTo = () => {
    scroller.scrollTo('scroll-to-element', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
    })
}

const Header = () => {
    return (
        <div className={classes.header}>
            <div className={classes.container}>
                <img src={logo} alt="Logo"/>
                <div className={classes.menu}>
                    <Link onClick={() => scrollTo()} to="about" className={classes.menuItem}><p>Про хаб</p></Link>
                    <Link onClick={() => scrollTo()} to="advices" className={classes.menuItem}><p>Поради з підтримки</p></Link>
                    <Link onClick={() => scrollTo()} to="consult" className={classes.menuItem}><p>Запис на консультацію</p></Link>
                    <Link onClick={() => scrollTo()} to="special" className={classes.menuItem}><p>База фахівців та сервісів</p></Link>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLScGxhE_-KWM2Si2Ie-JT2zdcrlgQd_WCUGXd2DekJ8RgxLfJQ/viewform" className={classes.menuItem}><p>Залишити відгук</p></a>
                </div>
                <div className={classes.burger}><MenuBtn /></div>
            </div>
        </div>
    )
}

export default Header;