import classes from './Initiators.module.css'
import lines from '../assets/img/lines.svg'
import fund from '../assets/img/fund.svg'
import brain from '../assets/img/brain.svg'

const Initiators = () => {
    return (
        <div className={classes.initiators}>
            <div className={classes.container}>
                <div className={classes.titleBlock}>
                    <h1 className={classes.title}>ініціатори</h1>
                    <img className={classes.lines} src={lines} alt="Lines"/>
                </div>
                <div className={classes.mainContent}>
                    <div className={classes.constantline}>
                        <img src={brain} alt="Фундація Суспільність"/>
                        <p><span>Фундація Суспільність</span> — спільнота медійників і журналістів. Одна з ініціаторок
                            Незалежної
                            медійної ради та Національного пресклубу, що співпрацює з провідними ЗМІ країни</p>
                    </div>
                    <div className={classes.constantline}>
                        <img src={fund} alt="БрейнКульт"/>
                        <p><span>БрейнКульт</span> — центр психічного здоров’я, який навчає,
                            супроводжує та консультує, щоб ви і ваше оточення вміли навігувати у складних життєвих
                            обставинах і знали, як розвивати свою життєстійкість</p>
                    </div>
                </div>
                <div className={classes.inTouch}>
                    <h3>На зв’язку з вами будемо ми:</h3>
                    <div className={classes.contacts}>
                        <div className={classes.members}><p>Анастасія Ніжнік</p><span>психотерапевтка і авторка програм психічного здоров'я</span></div>
                        <div className={classes.members}><p>Андрій Сидоренко</p><span>координатор розвитку Хабу стійкості</span></div>
                        <div className={classes.members}><p>Лілія Боднар</p><span>координаторка інформаційного напрямку</span></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Initiators;