// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Сalendly_calendly__yZbxR {\n    background: #FEED00;\n    border-radius: 7px;\n    width: 253px;\n    height: 48px;\n    font-family: Roboto, sans-serif;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 12px;\n    line-height: 14px;\n    letter-spacing: 0.08em;\n    text-transform: uppercase;\n    color: #000000;\n    text-decoration: none;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    cursor: pointer;\n    margin: 30px 15px 90px;\n}\n\n.Сalendly_calendly__yZbxR:hover {\n    background: #DAC6E1;\n}", "",{"version":3,"sources":["webpack://./src/pages/%D0%A1alendly.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,+BAA+B;IAC/B,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,sBAAsB;IACtB,yBAAyB;IACzB,cAAc;IACd,qBAAqB;IACrB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".calendly {\n    background: #FEED00;\n    border-radius: 7px;\n    width: 253px;\n    height: 48px;\n    font-family: Roboto, sans-serif;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 12px;\n    line-height: 14px;\n    letter-spacing: 0.08em;\n    text-transform: uppercase;\n    color: #000000;\n    text-decoration: none;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    cursor: pointer;\n    margin: 30px 15px 90px;\n}\n\n.calendly:hover {\n    background: #DAC6E1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"calendly": "Сalendly_calendly__yZbxR"
};
export default ___CSS_LOADER_EXPORT___;
