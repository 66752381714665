// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Footer_footer__EwVYi {\n\n}\n\n.Footer_container__CmmG- {\n    width: 100%;\n    max-width: 1140px;\n    margin: 0 auto;\n    padding: 0 15px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.Footer_container__CmmG- img {\n    width: 111px;\n    height: 111px;\n}\n\n.Footer_container__CmmG- p {\n    font-family: Roboto, sans-serif;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 14px;\n    line-height: 17px;\n    letter-spacing: 0.08em;\n    text-transform: uppercase;\n    color: #000000;\n}\n\n@media (max-width: 1140px) {\n\n    .Footer_footer__EwVYi {\n        padding: 0 18px;\n    }\n\n    .Footer_container__CmmG- {\n        max-width: 95%;\n        padding: 0;\n    }\n}", "",{"version":3,"sources":["webpack://./src/components/Footer.module.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,cAAc;IACd,eAAe;IACf,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,+BAA+B;IAC/B,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,sBAAsB;IACtB,yBAAyB;IACzB,cAAc;AAClB;;AAEA;;IAEI;QACI,eAAe;IACnB;;IAEA;QACI,cAAc;QACd,UAAU;IACd;AACJ","sourcesContent":[".footer {\n\n}\n\n.container {\n    width: 100%;\n    max-width: 1140px;\n    margin: 0 auto;\n    padding: 0 15px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.container img {\n    width: 111px;\n    height: 111px;\n}\n\n.container p {\n    font-family: Roboto, sans-serif;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 14px;\n    line-height: 17px;\n    letter-spacing: 0.08em;\n    text-transform: uppercase;\n    color: #000000;\n}\n\n@media (max-width: 1140px) {\n\n    .footer {\n        padding: 0 18px;\n    }\n\n    .container {\n        max-width: 95%;\n        padding: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "Footer_footer__EwVYi",
	"container": "Footer_container__CmmG-"
};
export default ___CSS_LOADER_EXPORT___;
