import Header from "../components/Header";
import Advices from "../components/Advices";
import AboutUs from "../components/AboutUs";
import Find from "../components/Find";
import Initiators from "../components/Initiators";
import Consultation from "../components/Consultation";
import Specialists from "../components/Specialists";
import Contacts from "../components/Contacts";
import Footer from "../components/Footer";


const Home = () => {
    return (
        <>
            <Header />
            <AboutUs />
            <Find />
            <Advices />
            <Consultation />
            <Specialists />
            <Initiators />
            <Contacts />
            <Footer />
        </>
    )
}

export default Home;