import classes from './Find.module.css'
import lines from '../assets/img/lines.svg'
import adviceslogo from '../assets/img/advicelogo.svg'
import serviceslogo from '../assets/img/serviceslogo.svg'
import socials from '../assets/img/social-care1.svg'
import consulting from '../assets/img/consulting.svg'

const Find = () => {
    return (
        <div className={classes.find}>
            <div className={classes.container}>
                <div>
                    <h1 className={classes.title}>Що я можу знайти у Хабі?</h1>
                    <img className={classes.lines} src={lines} alt="Lines"/>
                </div>
                <div className={classes.variants}>
                    <div className={classes.variantsColumn}>
                        <div className={classes.variant}>
                            <img src={adviceslogo} alt="Advices"/>
                            <p>поради для самодопомоги</p>
                        </div>
                        <div className={classes.variant}>
                            <img src={socials} alt="Socials"/>
                            <p>відкриту базу контактів психотерапевтів та психологів (телефони, пошти тощо)</p>
                        </div>
                    </div>
                    <div className={classes.variantsColumn}>
                        <div className={classes.variant}>
                            <img src={serviceslogo} alt="Services"/>
                            <p>українські та закордонні сервіси (боти, ресурси, додатки)</p>
                        </div>
                        <div className={classes.variant}>
                            <img src={consulting} alt="Consulting"/>
                            <p>можливість записатися на консультацію</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Find;