import classes from './Footer.module.css'
import logo from '../assets/img/logo.jpeg'

const Footer = () => {
    return (
        <div className={classes.footer}>
            <div className={classes.container}>
                <img src={logo} alt="Logo"/>
                <p>2022. Хаб стійкості</p>
            </div>
        </div>
    )
}

export default Footer;