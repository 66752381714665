import {Element} from "react-scroll";

import classes from './AboutUs.module.css'

import aboutus from '../assets/img/aboutus.svg'
import lines from '../assets/img/lines.svg'

const AboutUs = () => {
    return (
        <div className={classes.aboutus}>
            <Element name="about" />
            <div className={classes.container}>
                <div className={classes.content}>
                    <div className={classes.title}>
                        <h3>Про хаб</h3>
                        <img src={lines} alt="Lines"/>
                    </div>
                    <p>
                        Хаб стійкості – платформа з доступними сервісами для психологічної підтримки українців та українок у кризовий час.
                    </p>
                </div>
                <div className={classes.banner}><img src={aboutus} alt="About Us"/></div>
            </div>
        </div>
    )
}

export default AboutUs