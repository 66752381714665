import {Routes, Route, BrowserRouter} from 'react-router-dom';
import Home from "./pages/Home";
import './App.css'
import Calendly from "./pages/Calendly";

function App() {
  return (
      <BrowserRouter>
        <div className="max-w-screen-md mx-auto pt-20">
          <Routes>
            <Route exact path="/" element={<Home />}/>
            <Route exact path="/calendly/:id" element={<Calendly />}/>
          </Routes>
        </div>
      </BrowserRouter>
  );
}

export default App;
