import classes from './Contacts.module.css'
import lines from "../assets/img/lines.svg";
import mail from "../assets/img/mail.svg";
import fb from "../assets/img/fb.svg";
import inst from "../assets/img/inst.svg";

const Contacts = () => {
    return (
        <div className={classes.contacts}>
            <div className={classes.container}>
                <div className={classes.titleBlock}>
                    <h1 className={classes.title}>Контакти</h1>
                    <img className={classes.lines} src={lines} alt="Lines"/>
                </div>
                <p className={classes.questions}>З питаннями та пропозиціями звертайтеся:</p>
                <div className={classes.content}>
                    <a className={classes.contact} href="mailto:yearofresilience@gmail.com">
                            <img src={mail} alt="Mail"/>
                            <p>yearofresilience@gmail.com</p>
                    </a>
                    <a className={classes.contact} href="https://www.facebook.com/resilience.hub.u.a">
                        <img src={fb} alt="FB"/>
                        <p>Facebook</p>
                    </a>
                    <a className={classes.contact} href="https://www.instagram.com/resilience.hub.u.a">
                        <img src={inst} alt="Instagram"/>
                        <p>Instagram</p>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Contacts;